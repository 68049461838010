$(document).ready(function () {
    $('.faq .faq__question').click(function(){
      var parent = $(this).parent();
      var content = $(this).attr('data-question');
      var content_parent = $('.faq__answear[data-question="'+ content +'"]').parent();
      // Highlight question
      $(parent).find('.faq__question').removeClass('active');
      $(this).addClass('active');
      // Show content
      $(content_parent).find('.faq__answear').slideUp('fast').removeClass('active');
      $('.faq__answear[data-question="'+ content +'"]').slideDown('fast').addClass('active');
    });
  
});