import LazyLoad from "vanilla-lazyload";
require("./rwd-checker");
require("./form-animation");

$(function() {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    // aby użyć do elementów dodajemy klasę .lazy
    // zamiast src w img podajemy data-src
    // zamiast background-image podajemy data-bg
  });

  require("./cookie-bar");
  require("./animations");
 // require("./counter-animation");
  require("./dropdown-menu");
  require("./mobile-menu");
  require("./sticky-header");
  require("./pop-up");
  require("./simple-lightbox");
  require("./faq");
  require("./leafler-groupedlayercontrol");

  $(".gallery").each(function() {
    $(this)
      .find("a")
      .simpleLightbox();
  });
  
  if ($(".simplelightbox").length > 0) {
    $(".simplelightbox").simpleLightbox();
  }

  $('.form-offer #product').change(function(){
    if($(this).val() == 'PlasDECK'){
      $('.form-offer .plasdeck').slideDown();
    } else {
      $('.form-offer .plasdeck').slideUp();
    }
  });
  $( ".form-offer #product.disable" ).prop( "disabled", true ); //Disable

  // BG color on scroll
  $(window).on('scroll', function() {
    $('.offer .article').each(function() {
        if($(window).scrollTop() >= $(this).offset().top-300) {
            var id = $(this).attr('id');
            $('.bg-image').removeClass('active');
            $('.'+ id +'').addClass('active');
        }
    });
  });

  $(window).on('scroll', function() {
    var scroll = $(window).scrollTop();
    if ($(window).width() < 630) {
      if (scroll >= 100) {
          $(".mobile-bar").addClass("icons");
      } else {
          $(".mobile-bar").removeClass("icons");
      }
    }
  });


  // Languages dropdown
  $(document).ready(function(){
    $(".header--lang .dropdown .active-lang").appendTo('.header--lang .selected');
      $(".header--lang .selected").on('click', function(event) {
      $('.header--lang').toggleClass('opened');
    });
  });

  // Close when clicking outside
  $(document).bind('click', function(e){
    if (! $(e.target).parents().hasClass("header--lang")) {
      $(".header--lang").removeClass('opened');
    }
  });


  /*/ String to slug
  function convertToSlug(Text) {
    var Text = Text.toLowerCase()
    .replace(/^\s+|\s+$/g, '')
    .toLowerCase(/[^\w-]+/g, '');

    var from = "ãàáäâąęćżłóńźẽèéëêìíïîõòóöôùúüûñç·/_,:;";
    var to   = "aaaaaaeczlonzeeeeeiiiiooooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      Text = Text.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }
    Text = Text.replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');
    return Text;
}*/


  /*/ Add all H3 to sidebar list
  $('.subsite .sidebar .main-content h2, .subsite .sidebar .main-content h3').each(function() {
    var content = $(this).html(),
        slug = convertToSlug($(this).html());
    //$(this).attr('id', slug )
    $('.subsite .sidebar .links').append( '<a href="#'+ slug +'" data-highlight="'+ slug +'"><span>' + content +'</span></a>');
    $(this).parent('.section').attr('id', slug );
  });*/

  $(document).ready(function(){
    var pathname = window.location.pathname;
    var hash = window.location.hash;
    $('.subsite .sidebar .highlight-nav a[href^="' + pathname + '"]').addClass('highlighted');
    $('.subsite .sidebar .highlight-nav a[href^="' + hash + '"]').addClass('highlighted');
    
    $('.subsite .sidebar .highlight-nav a[href^="#"]').on('click', function() {
      $('.subsite .sidebar .highlight-nav a').removeClass('highlighted');
      $(this).addClass('highlighted');
    });
  });


  // Offset on click
  $('.subsite .sidebar .links a').on('click', function() {
    var id = $(this).attr('href');
    $([document.documentElement, document.body]).animate({
    scrollTop: $(id).offset().top - 100
  }, 500);
  });

  // Realizations filters
  $('.realizations__show-filters').on('click', function() {
    $('body').addClass('filters-visible');
  });
  $('.realizations__filters .close').on('click', function() {
    $('body').removeClass('filters-visible');
  });

  // Sticky column
  $('.sticky').each(function () {

    var sidebar = $(this),
        sidebarHeight = sidebar.outerHeight(),
        sidebarTop = sidebar.offset().top,
        sidebarStop = sidebar.closest('.sticky-wrapper').offset().top + sidebar.closest('.sticky-wrapper').outerHeight(),
        sidebarStopPosition = sidebarStop - sidebarHeight;
    
    // Offset
    if (sidebar.is('[data-offset]')) {
      var offset = sidebar.data('offset');
    } else {
      var offset = 0;
    }

    $(window).scroll(function () {
      var scrollTop = $(window).scrollTop();
      //$('.scrolltop').html('scrollTop ' +scrollTop +' ');
      if (sidebarTop < scrollTop + offset ){

        // Move
        sidebar.css('top', scrollTop - sidebarTop + offset );

        // Stop at bottom
        if (scrollTop > sidebarStopPosition - offset ) {
          sidebar.css('top', sidebarStopPosition - sidebarTop );
        }

      } else {
        // Remove if above
        sidebar.css('top', '0' )
      }

    });

  });

  
  /*/ Higlight active H2/H3 on scroll
  $(window).scroll(function () {
    var position = $(window).scrollTop();
    var nav = $('.highlight-offset').outerHeight();
    //var first = $('.subsite .main-content .section').first().offset().top;
    //var last = $('.subsite .main-content .section').last().outerHeight();
  
    $('.subsite .main-content .section').each(function () {
      var target = $(this).offset().top;
      var id = $(this).attr('id');
      var navLinks = $('.highlight-nav [data-highlight]');
      if (target-position-nav <= 0 ) {
        $('.highlight-nav .target').html(target);
        navLinks.removeClass('highlighted');
        $('.highlight-nav [data-highlight="' + id + '"]').addClass('highlighted');
        $('.subsite .main-content .section').removeClass('highlighted');
        $(this).addClass('highlighted');
      }
  
      // Remove if above first section
      if ($('.highlight-nav').hasClass('unhighlight-above') ) {
        if ($('.subsite .main-content .section').first().offset().top > position ){
          navLinks.removeClass('highlighted');
          $('.subsite .main-content .section').removeClass('highlighted');
        }
      }
  
      // Remove if under last section
      if ($('.highlight-nav').hasClass('unhighlight-under') && position-last > target ) {
        if ($('.subsite .main-content .section').last().outerHeight() > position ){
          navLinks.removeClass('highlighted');
          $('.highlight-section').removeClass('highlighted');
        }
      }
    });
  });*/

  
});
